<template>
  <cs-page-user class="views-user-company">
    <cs-panel-default title="企业信息" icon="icon-dingdanliebiao">
      <a-spin tip="信息加载中..." :spinning="loading">
        <div
          class="
            d-flex
            align-items-center
            justify-content-center
            user-company-content
          "
        >
          <img class="bg" src="../../../public/img/company.png" />
          <div class="content" v-if="formModel.companyId">
            <p class="title">{{ formModel.companyName }}</p>
            <p class="info">企业简称：{{ formModel.companyShortName }}</p>
            <p class="info">所属行业：{{ formModel.industry }}</p>
            <p class="info">统一社会信用代码：{{ formModel.creditCode }}</p>
            <p class="but" @click="editClick">修改信息></p>
          </div>
          <div class="content" v-else>
            <p class="title">您还未创建企业哟~</p>
            <a-button class="create-but" type="primary" @click="addClick"
              >创建企业</a-button
            >
          </div>
        </div>
      </a-spin>
    </cs-panel-default>
  </cs-page-user>
</template>
<script>
import CompanyEdit from "@/components/user/CompanyEdit";
export default {
  data() {
    return {
      formModel: {
        companyName: "",
        companyShortName: "",
        industry: "",
        companyId: "",
        creditCode: "",
      },
      loading: false,
    };
  },

  created() {
    this.initData();
  },

  methods: {
    initData() {
      // 企业信息 详情查询
      this.loading = true;
      this.api.service
        .fpc_oapi_company_selectCompanyInfoCheckUser({}, {})
        .then(async ({ code, data, msg }) => {
          await this.api.toast({ code, msg }, 0).catch(() => {
            this.$router.push("/user/index");
          });
          if (data) {
            this.formModel.companyId = data.id;
            this.formModel.companyName = data.companyName;
            this.formModel.companyShortName = data.companyShortName;
            this.formModel.industry = data.industry;
            this.formModel.creditCode = data.creditCode || "";
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 修改信息
    editClick() {
      this.api
        .component(CompanyEdit, {
          data: {
            ...this.formModel,
          },
        })
        .then(() => {
          this.initData();
        });
    },
    // 创建企业
    addClick() {
      this.api.component(CompanyEdit).then(() => {
        this.initData();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.views-user-company {
  .user-company-content {
    height: calc(100vh - 205px);

    .bg {
      width: 207px;
      height: auto;
    }

    .content {
      margin-left: 60px;
      .title {
        font-family: PingFangSC-Medium;
        font-size: 22px;
        color: #333333;
        letter-spacing: 0;
        line-height: 12px;
        font-weight: bold;
        margin-bottom: 30px;
      }

      .info {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #555555;
        letter-spacing: 0;
        line-height: 16px;
      }

      .create {
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        line-height: 18px;
      }

      .create-but {
        width: 135px;
        height: 46px;
        margin-top: 20px;
      }

      /deep/ .ant-btn {
        border-radius: 0;
      }

      .but {
        cursor: pointer;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: @primary-color;
        letter-spacing: 0;
        margin-top: 50px;
      }

      .but:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>